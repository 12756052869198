import request from '@/utils/request'

// 获取订单列表
export function getOrderList(query) {
	return request({
		url: '/api/boss/order/getOrderList',
		method: 'get',
		params: query
	})
}

// 按日期油枪销售额统计列表
export function salesGunStatistics(query) {
	return request({
		url: '/api/boss/order/salesGunStatistics',
		method: 'get',
		params: query
	})
}

// 销售额统计
export function salesStatistics(query) {
	return request({
		url: '/api/boss/order/salesStatistics',
		method: 'get',
		params: query
	})
}

// 更新已播报订单状态
export function upOrderVoice(data) {
	return request({
		url: '/api/boss/order/upOrderVoice',
		method: 'post',
		data
	})
}
// 补打小票
export function againReceipt1(data) {
	return request({
		url: '/api/boss/order/againReceipt',
		method: 'post',
		data
	})
}

// 获取最新未播报订单
export function getNewNoPlay(query) {
	return request({
		url: '/api/boss/order/getNewNoPlay',
		method: 'get',
		params: query
	})
}

// 获取订单统计
export function getOrderCount(query) {
	return request({
		url: '/api/boss/order/getOrderCount',
		method: 'get',
		params: query
	})
}
