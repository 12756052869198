<template>
  <div class="header min-width">
    <div class="logo">
      <div>我的加油站</div>
    </div>
    <div class="top-menu">
      <TopMenu />
    </div>
    <div class="header-right">
      <div class="header-user-con">
        <el-button type="danger" @click="clickChangeBroadcast">{{broadcastStatus ? "关闭":"开启"}}收款播报</el-button>
        <div class="user-avator">
        </div>
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link divflexxb">
            <img src="@/assets/images/logo.png" alt="" class="logo-img">
            <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item divided command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import TopMenu from '../topMenu/index.vue'
import { logout } from "../../api/user";
import Cookies from 'js-cookie'

export default {
  name: "commonHeader",
  props: ['changeBroadcast', 'broadcastStatus'],
  components: { TopMenu },
  data() {
    return {
      collapse: true,
      fullScreen: false,
      index: 1,
    };
  },

  methods: {
    // 下拉菜单选择
    handleCommand(commond) {
      if (commond === "logout") {
        this.$router.push("/");
        logout()
        .then((res) => {
          if (res.code === 200) {
            Cookies.remove('token')
            Cookies.remove('userInfo')
            this.$message.success('注销登陆成功')
          }
        })
        .catch(() => {
          Cookies.remove('token')
          Cookies.remove('userInfo')
      });
      }
    },


    clickChangeBroadcast() {
      this.$emit('changeBroadcast')
    },
  }
};
</script>

<style  scoped lang="scss">
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 22px;
  background-color: #6190e8;
  color: #ffffff;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.top-menu {
  width: 300px;
  height: 70px;
  margin-left: 80px;
  float: left;
}

.top-menu-item {
  width: 80px;
  height: 100%;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  line-height: 70px;
  color: #89a7e0;
  &:hover {
    color: #fff;
  }
}

.ht {
  color: #fff;
}

.header .logo {
  float: left;
  line-height: 70px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
  margin-left: 15px;
}
.logo-img {
  height: 70px;
}

.header-right {
  float: right;
  padding-right: 20px;
}
.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}
.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}
.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #999;
}
.btn-bell .el-icon-bell {
  color: #999;
}
.user-name {
  margin-left: 10px;
}
.user-avator {
  margin-left: 10px;
}
.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.el-dropdown-link {
  color: #ffffff;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.el-dropdown-menu__item {
  text-align: center;
}
.addmenu {
  transform: translateX(-110px);
}
</style>