<template>
  <div class="formPage">
    <el-menu :default-active="onRoute" class="el-menu-demo" mode="horizontal" background-color="#6190E8" text-color="#fff" active-text-color="#ffd04b" router unique-opened>
      <el-menu-item :index="item.index" v-for="(item,index) in activeList" :key="index" @click="$router.push(item.url)">{{item.name}}</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeList: [
        {
          name: "财务对账",
          index: "index",
          icon: ""
        },
        {
          name: "历史订单",
          index: "orderList",
          icon: ""
        },
        {
          name: "油枪调价",
          index: "gasSet",
          icon: ""
        }
      ],

    };
  },
  methods: {
    handleJump(index, url) {
      this.$router.push(url)
    }
  },
  computed: {
    // 路由配置
    onRoute() {
      return this.$route.path.replace("/", "");
    },
  },
}
</script>
<style lang="scss" scoped>
.formPage {
  width: 300px;
  height: 70px;
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 70px;
  .box {
    cursor: pointer;
    margin-right: 20px;
    font-size: 16px;
    .active {
      color: #fff;
    }
  }
}
.el-menu--horizontal > .el-menu-item {
  border-bottom: none;
}
.el-menu--horizontal,
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}
</style>