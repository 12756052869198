<template>
  <div class="wrapper">
    <common-header @changeBroadcast="changeBroadcast" :broadcastStatus="broadcastStatus"></common-header>
    <div class="content">
      <transition name="move" mode="out-in">
        <router-view></router-view>
      </transition>
      <el-backtop target=".content"></el-backtop>
    </div>
  </div>
</template>

<script>
import commonHeader from "./common/commonHeader";

import {  upOrderVoice, getNewNoPlay } from "@/api/order";
import { voicePrompt} from "@/utils/globalFunc";

export default {
  components: {
    commonHeader,
  },
  data() {
    return {
      collapse: true,
      timer: '',
      broadcastStatus: undefined,
      newNoPlayList: new Array,
      todyQuery: {
        pageNo: 1,
        pageSize: 10000,
        status: 2,
        startTime: `${this.getToDay()} 00:00:00`,
        endTime: `${this.getToDay()} 23:59:59`,
        gasId: JSON.parse(localStorage.getItem('userInfo')).gasId,
      },
      orderNo: 0
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },

  created() {
    // 播报模式刷新页面提示用户是否继续播报
    this.broadcastStatus = eval(localStorage.getItem('broadcastStatus'))
    if (this.broadcastStatus) { this.verifyBroadcast() }
  },
  methods: {
    // 获取当前时段10分钟内未播放订单
    getNewNoPlayList() {
      var query = {
        startTime: this.getLastTime(500),
        gasId: JSON.parse(localStorage.getItem('userInfo')).gasId,
        pageNo: 1,
        pageSize: 10,
        status: 2,
        voice: 0
      }
      getNewNoPlay(query).then((res) => {
        res.data.forEach(element => {
          this.newNoPlayList.push(element)
        });
      })
    },

    // 开启自动播放订单功能
    openBroadcast() {
      this.timer = setInterval(() => {
        if (this.newNoPlayList === undefined || this.newNoPlayList.length === 0 && this.broadcastStatus === true) {
          this.getNewNoPlayList()
          this.$bus.emit('updateList',true)
        } else {
          var order = this.newNoPlayList.pop()
          if (order.voice === 0) {
            var text = `${order.gunNum}号枪,${order.goodsName.replace('#',"号")}新能加油:${(order.orderMoney / 100).toFixed(2)}元`
            if (text !== null) {
              // 进行订单收款播报
              voicePrompt(text)
              // 播报完成后设置该条订单状态为已播放
              upOrderVoice({ gasId: order.gasId, id: order.id }).then((res) => {
                this.$bus.emit('updateList',true)
              })
            }
          }
        }
      }, 7000)
    },
    // 控制语音播报
    changeBroadcast() {
      var text = this.broadcastStatus ? "关闭" : "开启"
      this.$confirm(`此操作将${text}语音报表功能, 是否继续?`, '提示', {
        confirmButtonText: text,
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        clearInterval(this.timer)
        if (this.broadcastStatus) {
          this.broadcastStatus = false
          localStorage.setItem('broadcastStatus', false)
          this.$message.success('关闭语音播报成功')
          this.btnText = "开启"
        } else {
          this.openBroadcast()
          this.broadcastStatus = true
          localStorage.setItem('broadcastStatus', true)
          this.$message.success('开启语音播报成功')
          this.btnText = "关闭"
        }
      }).catch(() => {
        this.$message('已取消操作')
      });
    },

    // 页面刷新时提醒用户是否继续语音播报
    verifyBroadcast() {
      this.$confirm(`因您刷新页面，请确认是否继续使用语音播报`, '提示', {
        confirmButtonText: "继续播报",
        cancelButtonText: '停止播报',
        type: 'warning'
      }).then(() => {
        this.btnText = "关闭"
        this.openBroadcast()
      }).catch(() => {
        clearInterval(this.timer)
        this.btnText = "开启"
        this.broadcastStatus = false
        localStorage.setItem('broadcastStatus', false)
        this.$message('已取消语音播报')
      });
    },
  },
};
</script>

<style scoped>
</style>
